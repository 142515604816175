.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  background-color: #FFFFFF;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 700px;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 44px;
}

.header {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  text-underline-position: from-font;
  color: #000000DE;
  text-decoration-skip-ink: none;
}

.label {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000DE;
}

.data {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000DE;
}

.green {
  color: #4CAF50;
}

.btnWrapper {
  text-align: end;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
