.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 48px;
}

.pagination-container.css-1l5xwdx-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: rgba(244,147,54,0.93);
}

.empty-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.empty-data-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.empty-data-text {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #00000099;
}

