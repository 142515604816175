.container {
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
    display: flex;
}

.dataContainer {
    background-color: #FFFFFF;
    width: 81%;
    height: 784px;
    margin-top: 40px;
    margin-left: 24px;
    padding: 24px 40px 24px 40px;
    border-radius: 8px 0 0 0;
}

.controls {
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
}

.filterContainer {
    display: flex;
    gap: 8px;
}

@media screen and (min-width: 1440px)  {

}
