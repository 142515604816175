.btn {
    width: 100%;
    height: 42px;
    padding: 8px 22px 8px 22px;
    border-radius: 6px;
    display: inline-block;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    background: #F49336;
    box-shadow:
            0 3px 1px -2px #00000033,
            0 2px 2px 0 #00000024,
            0 1px 5px 0 #0000001F;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46000000834465027px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
}

.disabled {
    color:  #00000061;
    background: #0000001F;
    box-shadow: none;
}
