.input {
  height: 40px;
  width: 100%;
  background-color: inherit;
  border-radius: 6px;
  border: 1px solid #0000003B;
  color: #000000DE;
  text-indent: 30px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  box-sizing: border-box;
  padding-right: 12px;

  &:focus {
    border: 2px solid #F49336;
  }

  &:focus + .label {
    transform: translateY(-4px);
    font-size: 12px;
    line-height: 12px;
    padding-right: 4px;
    color: #F49336;
  }
}

.container {
  position: relative;
  width: 180px;
}

.label {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  position: absolute;
  background-color: #FFFFFF;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  order: -1;
  padding-left: 5px;
  top: -3px;
  left: 26px;
  transition: all 0.3s ease-in;
  transform: translateY(12px);
  pointer-events: none;
  font-size: 16px;
  line-height: 24px;
  color: #00000099;
}

.active {
  transform: translateY(-4px);
  font-size: 12px;
  line-height: 12px;
  padding-right: 4px;
}

.searchIcon {
  position: absolute;
  left: 2px;
  top: 9px;
  padding-left: 2px;
  background-color: #FFFFFF;
}

@media screen and (min-width: 1300px)  {
  .container {
    width: 200px;
  }
}

@media screen and (min-width: 1348px)  {
  .container {
    width: 248px;
  }
}
