.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F9F9F9;
}

.container {
  width: 500px;
  background: #FFFFFF;
  padding: 32px 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
}

.header {
  text-align: center;
}

.inputsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.logo {
  width: 104px;
}

.errorMessage {
  position: absolute;
  bottom: -18px;
  left: 14px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #D32F2F;
}
