.container {
    width: 210px;
    height: 100%;
    padding: 24px 0 24px 16px;
    justify-content: space-between;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 180px;
}

.email {
    overflow: hidden;
    text-overflow: ellipsis;
}

.topItems {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.tabItem {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #F49336;
    border-right: 2px solid #F49336;
    gap: 8px;
    padding-left: 16px;
}

.btnWrapper {
    width: 109px;
}

@media screen and (min-width: 1440px)  {
    .container {
        width: 250px;
    }

    .header {
        width: 210px;
    }
}
